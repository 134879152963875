import styled from "styled-components";
import { useEffect , useState } from "react";
import "../css/Home.css";
import {keyframes} from "styled-components";
import { Link, useLocation } from "react-router-dom";
import CheckMarkImg from "../assets/checkmark.webp"
import BatsuMarkImg from "../assets/batsumark.webp"
import React from "react";
import ReactGA from "react-ga4";


const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

const TopImgWrapper = styled.div`
    text-align:center;
    width:100%;
`
const TopImgStyle = styled.img`
    width:100%;
    max-width:2000px;
`

const NsLogoWrapper = styled.div`
    width:100%;
    text-align:center;
`
const NsLogoImgStyle = styled.img`
    width:300px;
    @media (max-width: 768px) {
        width:250px;
    }
    @media (max-width: 375px) {
        width:190px;
    }
`

const Section = styled.div`
    width:100%;
    text-align:center;
    margin-top:30px;
    margin-bottom:30px;
`
const SectionTitle = styled.div`
    color:#0da4de;
    font-weight:bold;
    font-size:45px;
    margin-top:20px;
    margin-bottom:20px;
    text-align:center;
    @media (max-width: 768px) {
        font-size:35px;
    }
    @media (max-width: 375px) {
        font-size:25px;
        margin-bottom:10px;
    }
`
const SectionSpacer = styled.p`
    width:100%;
    height:60px;
    @media (max-width: 375px) {
        height:10px;
    }
`
const SectionExplanation = styled.p`

`

const ProductListArea = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 1020px) {
        display: block;
    }
`
interface productArrow {
    pointer:boolean,
}
const ProductLeftArrow = styled.div<productArrow>`
    background: #c0c0c0;/* silver */
    height: 100px;
    width: 100%;
    max-width:20px;
    margin:auto;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    cursor:pointer;
    pointer-events:${props => props.pointer?"auto":"none"};
    @media (max-width: 1020px) {
        display:none;
    }
    &:hover{
        opacity:0.6;
    }
`
const ProductRightArrow = styled.div<productArrow>`
    background: #c0c0c0;/* silver */
    height: 100px;
    width: 100%;
    max-width:20px;
    margin:auto;
    clip-path: polygon(0 0, 0 100%, 100% 50%);
    cursor:pointer;
    pointer-events:${props => props.pointer?"auto":"none"};
    @media (max-width: 1020px) {
        display:none;
    }
    &:hover{
        opacity:0.6;
    }
`
const TabletProductUpArrow = styled.div<productArrow>`
    display:none;
    @media (max-width: 1020px) {
        display:block;
        background: #c0c0c0;/* silver */
        height: 30px;
        width:120px;
        margin:auto;
        clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
        cursor:pointer;
        pointer-events:${props => props.pointer?"auto":"none"};
    }
    @media (max-width: 768px) {
        width:80px;
    }
    @media (max-width: 375px) {
        display:none;
    }
    &:hover{
        opacity:0.6;
    }
`
const PhoneProductUpArrow = styled.div<productArrow>`
    display:none;
    @media (max-width: 375px) {
        display:block;
        background: #c0c0c0;/* silver */
        height:15px;
        width:50px;
        margin:auto;
        clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
        cursor:pointer;
        pointer-events:${props => props.pointer?"auto":"none"};
    }
    &:hover{
        opacity:0.6;
    }
`
const TabletProductDownArrow = styled.div<productArrow>`
    display:none;
    @media (max-width: 1020px) {
        display:block;
        background: #c0c0c0;/* silver */
        height: 30px;
        width:120px;
        margin:auto;
        clip-path: polygon(0 0, 100% 0%, 50% 100%);
        cursor:pointer;
        pointer-events:${props => props.pointer?"auto":"none"};
    }
    @media (max-width: 768px) {
        width:80px;
    }
    @media (max-width: 375px) {
        display:none;
    }
    &:hover{
        opacity:0.6;
    }
`
const PhoneProductDownArrow = styled.div<productArrow>`
    display:none;
    @media (max-width: 375px) {
        display:block;
        background: #c0c0c0;/* silver */
        height:15px;
        width:50px;
        margin:auto;
        clip-path: polygon(0 0, 100% 0%, 50% 100%);
        cursor:pointer;
        pointer-events:${props => props.pointer?"auto":"none"};
    }
    &:hover{
        opacity:0.6;
    }
`
const PhoneProductArrowExp = styled.p`
    display:none;
    color:silver;
    @media (max-width: 1020px) {
        display:inline-block;
        font-size:13px;
    }
    @media (max-width: 768px) {
        font-size:10px;
    }
    @media (max-width: 375px) {
        font-size:8px;
    }
`
const ProductListWrapper = styled.div`
    display: flex;
    display: -webkit-flex; /* Safari */
    overflow: hidden;
    align-items: center;
    max-width:960px;
    width:100%;
    height:380px;
    @media (max-width: 768px) {
        height:340px;
    }
    @media (max-width: 375px) {
        height:280px;
    }
`
interface productSlideProps {
    anim:boolean,
    display:string,
}

const slideActionLeft = keyframes`
  from{
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
` 
const mainSlideActionLeft = keyframes`
  from{
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
` 
const slideActionRight = keyframes`
from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
` 
const mainSlideActionRight = keyframes`
from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
` 
const mainSlideActionUp = keyframes`
from {
    transform: translateY(0%);
    opacity:1;
  }
  to {
    transform: translateY(-200%);
    opacity:0.2;
  }
` 
const mainSlideActionDown = keyframes`
from {
    transform: translateY(0%);
    opacity:1;
  }
  to {
    transform: translateY(200%);
    opacity:0.2;
  }
` 
const fadeIn = keyframes`
from {
    opacity:0;
  }
  to {
    opacity:1;
  }
` 
const PhoneProductSliderM = styled.ul<productSlideProps>`
    display:none;
    @media (max-width: 375px) {
        display:${props => props.display === "true"?"-webkit-flex":"none"}; /* Safari */
        display:${props => props.display === "true"?"flex":"none"};
        justify-content:center;
        -webkit-justify-content:center;
        gap:7%;
        width:100%;
        list-style:none;
        padding-left:0;
        animation:${props => props.anim ?fadeIn:""} 0.7s 1 ease-in;
    }
`
const PhoneProductSlider1 = styled.ul<productSlideProps>`
    display:none;
    @media (max-width: 375px) {
        display:${props => props.display === "true"?"-webkit-flex":"none"}; /* Safari */
        display:${props => props.display === "true"?"flex":"none"};
        justify-content:center;
        -webkit-justify-content:center;
        gap:7%;
        width:100%;
        list-style:none;
        padding-left:0;
        animation:${props => props.anim ?mainSlideActionUp:""} 0.7s 1 ease-in;
    }
`
const PhoneProductSlider2 = styled.ul<productSlideProps>`
    display:none;
    @media (max-width: 375px) {
        display:${props => props.display === "true"?"-webkit-flex":"none"}; /* Safari */
        display:${props => props.display === "true"?"flex":"none"};
        justify-content:center;
        -webkit-justify-content:center;
        gap:7%;
        width:100%;
        list-style:none;
        padding-left:0;
        animation:${props => props.anim ?mainSlideActionDown:""} 0.7s 1 ease-in;
    }
`
const TabletProductSliderM = styled.ul<productSlideProps>`
    display:none;
    @media (max-width: 1020px) {
        display:${props => props.display === "true"?"-webkit-flex":"none"}; /* Safari */
        display:${props => props.display === "true"?"flex":"none"};
        justify-content:center;
        -webkit-justify-content:center;
        gap:7%;
        width:100%;
        list-style:none;
        padding-left:0;
        animation:${props => props.anim ?fadeIn:""} 0.7s 1 ease-in;
    }
    @media (max-width: 375px) {
        display:none;
    }
`
const TabletProductSlider1 = styled.ul<productSlideProps>`
    display:none;
    @media (max-width: 1020px) {
        display:${props => props.display === "true"?"-webkit-flex":"none"}; /* Safari */
        display:${props => props.display === "true"?"flex":"none"};
        justify-content:center;
        -webkit-justify-content:center;
        gap:7%;
        width:100%;
        list-style:none;
        padding-left:0;
        animation:${props => props.anim ?mainSlideActionUp:""} 0.7s 1 ease-in;
    }
    @media (max-width: 375px) {
        display:none;
    }
`
const TabletProductSlider2 = styled.ul<productSlideProps>`
    display:none;
    @media (max-width: 1020px) {
        display:${props => props.display === "true"?"-webkit-flex":"none"}; /* Safari */
        display:${props => props.display === "true"?"flex":"none"};
        justify-content:center;
        -webkit-justify-content:center;
        gap:7%;
        width:100%;
        list-style:none;
        padding-left:0;
        animation:${props => props.anim ?mainSlideActionDown:""} 0.7s 1 ease-in;
    }
    @media (max-width: 375px) {
        display:none;
    }
`
const MProductSlider1 = styled.ul<productSlideProps>`
    display: -webkit-flex; /* Safari */
    display:${props => props.display === "true"?"flex":"none"};
    justify-content:center;
    -webkit-justify-content:center;
    gap:7%;
    width:100%;
    min-width:960px;
    list-style:none;
    padding-left:0;
    animation:${props => props.anim ?mainSlideActionLeft:""} 0.7s 1 ease-in;
    @media (max-width: 1020px) {
        display:none;
    }
`
const MProductSlider2 = styled.ul<productSlideProps>`
    display: -webkit-flex; /* Safari */
    display:${props => props.display === "true"?"flex":"none"};
    justify-content:center;
    -webkit-justify-content:center;
    gap:7%;
    width:100%;
    min-width:960px;
    list-style:none;
    padding-left:0;
    animation:${props => props.anim ?mainSlideActionRight:""} 0.7s 1 ease-in;
    @media (max-width: 1020px) {
        display:none;
    }
`
const SubProductSlider1 = styled.ul<productSlideProps>`
    display: -webkit-flex; /* Safari */
    display: ${props => props.display === "true"?"flex":"none"};
    justify-content:center;
    -webkit-justify-content:center;
    width:100%;
    min-width:960px;
    gap:7%;
    list-style:none;
    padding-left:0;
    animation:${props => props.anim ?slideActionLeft:""} 0.7s 1 ease-out;
    @media (max-width: 1020px) {
        display:none;
    }
`
const SubProductSlider2 = styled.ul<productSlideProps>`
    display: -webkit-flex; /* Safari */
    display: ${props => props.display === "true"?"flex":"none"};
    justify-content:center;
    -webkit-justify-content:center;
    width:100%;
    min-width:960px;
    gap:7%;
    list-style:none;
    padding-left:0;
    animation:${props => props.anim?slideActionRight:""} 0.7s 1 ease-out;
    @media (max-width: 1020px) {
        display:none;
    }
    
`
interface ProductLinkProps {
    conDisplay:boolean
}
const ProductContainer = styled.li<ProductLinkProps>`
    display:${(props) => props.conDisplay?"auto":"none"};
    width:150px;
    background:#d3d3d3;
    cursor:pointer;
    transition: all 0.5s 0s ease;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:20px;
`
const ProductIconWrapper = styled.div`
    padding-left:5px;
    padding-right:5px;
`

const ProductIcon = styled.img`
    width:100%;
    margin-top:20px;
    margin-bottom:10px;
    margin-left:auto;
    margin-right:auto;
    
    @media (max-width: 375px) {
        
    }
`
const ProductName = styled.div`
    margin-top:20px;
    font-weight:bold;
    @media (max-width: 375px) {
        margin-bottom:0px;
    }
`
const ProductElement = styled.div`
    height:30px;
    margin-top:20px;
    font-weight:bold;
    @media (max-width: 375px) {
        display:none;
    }
`

const ServiceListArea = styled.div`
    
`
const ServiceListWrapper = styled.div`
    width:100%;
    min-height:650px;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    @media (max-width: 768px) {
        flex-wrap:wrap;
    }
    @media (max-width: 375px) {
        display: inline-block;
    }
`
const ServiceCompNameEmp = styled.span`
    margin:0;
    font-size:25px;
    @media (max-width: 768px) {
        font-size:18px;
    }
    @media (max-width: 375px) {
        font-size:14px;
    }
`
const ServiceContainer = styled.div`
    width:23%;
    max-width:290px;
    background:#d3d3d3;
    margin-right:10px;
    margin-left:10px;
    color:#000;
    @media (max-width: 768px) {
        width:40%;
        margin-bottom:10px;
    }
    @media (max-width: 500px) {
        width:70%;
    }
    @media (max-width: 375px) {
        margin-left:auto;
        margin-right:auto;
        width:100%;
    }
`
const ServiceIcon = styled.img`
    width:100%;
`
const ServiceName = styled.div`
    height:80px;
    margin-top:20px;
    margin-bottom:10px;
    margin-left:15px;
    margin-right:15px;
    font-size:22px;
    font-weight:bold;
    @media (max-width: 768px) {
        font-size:20px;
    }
    @media (max-width: 375px) {
        height:50px;
        font-size:14px;
        margin-top:10px;
    }
`
const ServiceSub = styled.div`
    height:80px;
    margin-top:20px;
    font-size:17px;
    font-weight:bold;
    @media (max-width: 375px) {
        font-size:13px;
        height:30px;
    }
`
const ServiceExplanation = styled.div`
    margin-left:20px;
    margin-right:20px;
    font-size:14px;
    text-align:left;
    @media (max-width: 768px) {
        margin-bottom:20px;
    }
    @media (max-width: 375px) {
        font-size:10px;
    }
`

const InstanceListArea = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
`
const InstanceListWrapper = styled.div`
    width:100%;
    max-width:1225px;
    height:500px;
    display: -webkit-flex; /* Safari */
    display: flex;
    position:relative;
    @media (max-width: 768px) {
        height:300px;
    }
    @media (max-width: 375px) {
        height:350px;
        display:block;
    }
`
const InstanceExpContainer1 = styled.div`
    position:absolute;
    background:#d3d3d3;/* lightgray */
    right:50%;
    top:10%;
    max-width:460px;
    padding:40px;
    text-align:left;
    @media (max-width: 768px) {
        font-size:12px;
        width:250px;
        padding:20px;
        right:50%;
        top:6%;
    }
    @media (max-width: 595px) {
        left:0%;
    }
    @media (max-width: 375px) {
        position:static;
        font-size:10px;
        width:80%;
    }
`
const InstanceExpContainer2 = styled.div`
    position:absolute;
    background:#d3d3d3;/* lightgray */
    left:50%;
    top:10%;
    max-width:460px;
    padding:40px;
    text-align:left;
    @media (max-width: 768px) {
        font-size:12px;
        width:250px;
        padding:20px;
        left:50%;
        top:6%;
    }
    @media (max-width: 595px) {
        right:0%;
    }
    @media (max-width: 375px) {
        position:static;
        font-size:10px;
        width:80%;
    }
`
const InstanceContainerTitle = styled.p`
    font-weight:bold;
    font-size:30px;
    margin-top:10px;
    margin-bottom:10px;
    color: #000;
    @media (max-width: 768px) {
        font-size:20px;
    }
    @media (max-width: 375px) {
        font-size:13px;
        margin-top:5px;
        margin-bottom:5px;
    }
`
const InstanceContainerCompany = styled.p`
    font-size:18px; 
    @media (max-width: 375px) {
        margin:0;
    }
    color: #000;
`
const InstanceContainerText = styled.p`
    color: #000;
    @media (max-width: 375px) {
        margin:0;
        display:none;
    }
`
const InstanceImg1 = styled.img`
    width:853px;
    margin-left:auto;
    @media (max-width: 768px) {
        width:480px;
    }
    @media (max-width: 375px) {
        width:100%;
        margin:0;
    }
`
const InstanceImg2 = styled.img`
    width:853px;
    margin-right:auto;
    @media (max-width: 768px) {
        width:480px;
    }
    @media (max-width: 375px) {
        width:100%;
        margin:0;
    }
`

const NewsArea = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    width:100%;
    margin-top:50px;
    
    @media (max-width: 375px) {
        margin-top:30px;
    }
`
const NewsGridArea = styled.div`
    width:70%;
    max-width:1225px;
    display: grid;
    grid-template-columns: 100%;
    row-gap:40px;
    row-gap:25px;
    @media (max-width: 768px) {
        width:80%;
        row-gap:30px;
    }
    @media (max-width: 375px) {
        width:100%;
        row-gap:20px;
    }
`
const NewsGridRow = styled.div`
    display: grid;
    grid-template-columns: 15% 5% 25% 5% 50%;
    
    @media (prefers-color-scheme: dark) {
        border-bottom:1px solid #FFFFFF;
    }
    @media (prefers-color-scheme: light) {
        border-bottom:1px solid #000000;
    }
`
const NewsGridBlock = styled.div`
    text-align:left;
`
const NewsGridToggleArea = styled.div`
    width:100%;
    text-align:center;
`
const NewsGridToggle = styled.div`
    font-weight:bold;
    font-size:20px;
    cursor:pointer;
    margin-top:20px;
    @media (max-width: 375px) {
        font-size:15px;
    }
`

const InquiryWrapper = styled.div`
    width:80%;
    max-width:1225px;
    margin-left:auto;
    margin-right:auto;
    background:#d3d3d3;
    padding-top:20px;
    padding-bottom:20px;
    @media (max-width: 375px) {
        width:100%;
    }
`
const InquiryArea = styled.div`
    @media (min-width: 768px) {
        display: -webkit-flex; /* Safari */
        display: flex;
        width:90%;
        margin-left:auto;
        margin-right:auto;
    }
`
const InquiryGridArea = styled.div`
    width:100%;
    max-width:1225px;
    display: grid;
    grid-template-columns: 45% 10% 45%;
    grid-row-gap:15px;
    grid-auto-rows: 50px;
    @media (max-width: 768px) {
        width:90%;
        margin-left:auto;
        margin-right:auto;
    }
    @media (max-width: 375px) {
        grid-row-gap:0px;
    }
`
const InquiryGridBlock = styled.input`
    border:0;
    ::placeholder {
        font-size:14px;
        font-family: 'meiryo',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }

    @media (max-width: 375px) {
        height:30px;
    }
`
const InquiryTextArea = styled.textarea`
    border:0;
    resize: none;
    ::placeholder {
        font-size:14px;
        font-family: 'meiryo',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
`
const GoogleMapField = styled.div`
    width:100%; 
    display:flex;
    display:-webkit-flex;
    flex-flow: column;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    margin-left:5%;
    @media (max-width: 768px) {
        width:90%;
        margin-top:30px;
        margin-left:auto;
        margin-right:auto;
    }
`
const GoogleMap = styled.iframe`
    border:0;
    @media (min-width: 768px) {
        width:100%;
        height:100%;
        margin-top:0px;        
    }
    @media (max-width: 768px) {
        width:100%;
        height:300px;
        margin-left:auto;
        margin-right:auto;
    }
`
const InquiryAddress = styled.span`
    width:100%;
    color: #000;
`
const InquirySubmitButtonArea = styled.div`
    margin-top:30px;
    width:100%;
    text-align:center;
`
interface submitButtonProps {
    submitButtonColor:string,
    submitButtonPoint:string
}
const InquirySubmitButton = styled.input<submitButtonProps>`
    width:300px;
    height:50px;
    font-size:20px;
    font-weight:bold;
    color:white;
    cursor:pointer;
    background:${(props) => props.submitButtonColor};
    pointer-events:${(props) => props.submitButtonPoint};
    border-radius:10px;
    border:0;
    @media (max-width: 768px) {
        font-size:20px;
        width:200px;
    }
    @media (max-width: 375px) {
        font-size:16px;
        width:100px;
    }
`
const LinkTo = styled(Link)`
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`
const ProductLinkTo = styled(Link)`
    text-decoration:none;
    color:#000000;
`
const InquiryType = styled.select`
    border:0;
    font-size:14px;
    font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
                'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    @media (max-width: 768px) {
        font-size:12px;
    }
    @media (max-width: 375px) {
        font-size:10px;
    }
    ::placeholder {
    }
    @media (max-width: 375px) {
        height:30px;
    }
`
const InquiryTypeOption = styled.option`
    font-size:14px;
    font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
                'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    @media (max-width: 768px) {
        font-size:12px;
    }
    @media (max-width: 375px) {
        font-size:10px;
    }
`
interface postMessageProps {
    postFlag:boolean
}
const AlertCurtain = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:299;
    background:#000;
    opacity:0.4;
`
const InquiryAlertArea = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    top:calc(50% - 110px);
    right:calc(50% - 170px);
    position:fixed;
    width:250px;
    height:220px;
    padding-top:25px;
    padding-bottom:25px;
    padding-right:45px;
    padding-left:45px;
    background:#fff;
    z-index:300;
    border:1px solid black;
    text-align:center;
    border-radius:10px;
    @media (max-width: 768px) {
        top:calc(50% - 100px);
        right:calc(50% - 135px);
        width:200px;
        height:170px;
        padding-top:15px;
        padding-bottom:15px;
        padding-right:35px;
        padding-left:35px;
    }
    @media (max-width: 375px) {
        top:calc(50% - 75px);
        right:calc(50% - 100px);
        width:160px;
        height:120px;
        padding-top:10px;
        padding-bottom:20px;
        padding-right:20px;
        padding-left:20px;
    }
`
const InquiryAlertImg = styled.img`
    width:150px;
    @media (max-width: 768px) {
        width:120px;
    }
    @media (max-width: 375px) {
        width:70px;
    }
`
const InquiryAlert = styled.div`
    width:250px;
    font-weight:bold;
    margin-bottom:20px;
    @media (max-width: 768px) {
        width:200px;
    }
    @media (max-width: 375px) {
        width:160px;
    }
`
const InquiryAlertButton = styled.button`
    width:100px;
    padding:15px;
    font-weight:bold;
    color:white;
    background:#87cefa;
    border-radius:10px;
    border:0;
    cursor:pointer;
    :hover{
        background:#00bfff;
    }
    @media (max-width: 768px) {
        width:100px;
        padding:10px;
    }
    @media (max-width: 375px) {
        width:70px;
        padding:8px;
    }
`
const InquiryErrorArea = styled.div`
    width:100%;
    display: -webkit-flex; /* Safari */
    display: flex;
    text-align:left;
    margin-bottom:20px;    
`
interface errorProps {
    errorFlag:boolean
}
const InquiryErrorImg = styled.img<errorProps>`
    display:${(props) => props.errorFlag?"auto":"none"};
    width:50px;
    margin-bottom:auto;
    margin-top:auto;
    margin-left:10px;
    margin-right:10px;
    @media (max-width: 768px) {
        width:40px;
    }
    @media (max-width: 375px) {
        width:30px;
    }
`
const InquiryError = styled.div`
    margin-bottom:auto;
    margin-top:auto;
`
interface State {
    id: string;
}
const manual_scroll = function(to : any){
    var smoothScrollFeature = 'scrollBehavior' in document.documentElement.style;
    // var articles : any = document.querySelectorAll("ul#content > li"), i: number;
    // if (to == 'elem') to = articles[id].offsetTop;
    var i : number = parseInt(String(window.pageYOffset));
    if ( i !== to ) {
        if (!smoothScrollFeature) {
            to = parseInt(to);
            if (i < to) {
                var int = setInterval(function() {
                    if (i > (to-20)) i += 1;
                    else if (i > (to-40)) i += 3;
                    else if (i > (to-80)) i += 8;
                    else if (i > (to-120)) i += 12;
                    else if (i > (to-160)) i += 18;
                    else if (i > (to-200)) i += 24;
                    else if (i > (to-250)) i += 30;
                    else if (i > (to-300)) i += 40;
                    else i += 60;
                    window.scroll(0, i);
                    if (i >= to) clearInterval(int);
                }, 10);
            }
            else {
                // eslint-disable-next-line
                var int = setInterval(function() {
                    if (i < (to+20)) i -= 1;
                    else if (i < (to+40)) i -= 3;
                    else if (i < (to+80)) i -= 8;
                    else if (i < (to+120)) i -= 12;
                    else if (i < (to+160)) i -= 18;
                    else if (i < (to+200)) i -= 24;
                    else if (i < (to+250)) i -= 30;
                    else if (i < (to+300)) i -= 40;
                    else i -= 60;
                    window.scroll(0, i);
                    if (i <= to) clearInterval(int);
                }, 10);
            }
        }
        else {
            window.scroll({
                top: to,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
};
const using_funcScroll = (coordinate:any) => {
    window.scrollTo({
        top: coordinate,
        left: 0,
        behavior: 'smooth'});
}               

export const Home = () =>{
    const location = useLocation();
    const scrollId : State = location.state as State;
    
    useEffect(() => {
        
        try{
            setTimeout(() =>{
                scrollController(scrollId?.id)},100);
        }catch(e){}
        // eslint-disable-next-line
    },[]);
    
    const agent = window.navigator.userAgent.toLowerCase();
    const windowScroller = agent.indexOf("safari") !== -1 || agent.indexOf("edg") !== -1 || agent.indexOf("edge") !== -1?manual_scroll:using_funcScroll;
    const scrollController = (page:string) => {
        let client_h : any = document.getElementById("suiteup_header")?.clientHeight;
        switch(page){
            case "home":
                windowScroller(0);
            break
            case "product":
                const element1 : any = document.getElementById("product_section");
                // console.log(element1.offsetTop);
                windowScroller(element1.offsetTop-client_h);
            break;
            case "service":
                const element2 : any = document.getElementById("service_section");
                // console.log(element2.offsetTop);
                windowScroller(element2.offsetTop-client_h);
            break;
            case "case":
                const element3 : any = document.getElementById("instance_section");
                // console.log(element3.offsetTop);
                windowScroller(element3.offsetTop-client_h);
            break;
            case "news":
                const element4 : any = document.getElementById("news_section");
                // console.log(element4.offsetTop);
                windowScroller(element4.offsetTop-client_h);
            break;
            case "inquiry":
                const element5 : any = document.getElementById("inquiry_section");
                // console.log(element5.offsetTop);
                windowScroller(element5.offsetTop-client_h);
            break;
        }
    }
    // smartphone
    const [mainUpFade,setMainUpFade] = useState<boolean>(false);
    const [mainDownFade,setMainDownFade] = useState<boolean>(false);
    const [centerDisplay,setCenterDisplay] = useState<boolean>(true);
    const [upDisplay,setUpDisplay] = useState<boolean>(false);
    const [downDisplay,setDownDisplay] = useState<boolean>(false);

    // PC
    const [mainLeftSlide,setMainLeftSlide] = useState<boolean>(true);
    const [mainLeftAnim,setMainLeftAnim] = useState<boolean>(false);
    const [mainRightSlide,setMainRighttSlide] = useState<boolean>(true);
    const [mainRightAnim,setMainRighttAnim] = useState<boolean>(false);
    const [subLeftAnim,setSubLeftAnim] = useState<boolean>(false);
    const [subLeftSlide,setSubLeftSlide] = useState<boolean>(false);
    const [subRightAnim,setSubRightAnim] = useState<boolean>(false);
    const [subRightSlide,setSubRightSlide] = useState<boolean>(false);
    const [pointerState,setPointerState] = useState<boolean>(true);
    const [currentProduct,setCurrentProduct] = useState<number>(0);
    const [nextProduct,setNextProduct] = useState<number>(0);
    const [currentProductTab,setCurrentProductTab] = useState<number>(0);
    const [currentProductSm,setCurrentProductSm] = useState<number>(0);
    const [conDisplay1] = useState<boolean>(true);
    const [conDisplay2] = useState<boolean>(true);
    const [conDisplay3] = useState<boolean>(true);
    const [conDisplay4] = useState<boolean>(true);

    //News toggle
    const [newsToggleFlag,setNewsToggleFlag] = useState<boolean>(false);

    //inqury
    const inquiry_ref_name : any = React.createRef();
    const inquiry_ref_email : any = React.createRef();
    const inquiry_ref_companyName : any = React.createRef();
    const inquiry_ref_phone : any = React.createRef();
    const inquiry_ref_type : any = React.createRef();
    const inquiry_ref_comment : any = React.createRef();
    const [submitButtonColor,setSubmitButtonColor] : any = useState<string>("#89c3eb");
    const [submitButtonPoint,setSubmitButtonPoint] : any = useState<string>("none");
    const [errorFlag,setErrorFlag] : any = useState<boolean>(false);
    const [errorEmail,setErrorEmail] : any = useState<string>("");
    const [errorPhone,setErrorPhone] : any = useState<string>("");
    const [postFlag,setPostFlag] : any = useState<boolean>(false);

    interface ProductItem {
        name: string,
        element: string,
        url:string,
        img:string
    }
    let productsPC : Array<ProductItem[]> = [];
    let productsTab : Array<ProductItem[]> = [];
    let productsSm : Array<ProductItem[]> = [];
    let toggleListToken : any= [];
    let caseAreaState : any= [];

    let caseFile = new XMLHttpRequest();
    caseFile.open("GET", `${process.env.PUBLIC_URL}/state_data/case_state.json`, false);        
    caseFile.onreadystatechange = () => {
        if (caseFile.readyState === 4) {                
            if (caseFile.status === 200 || caseFile.status === 0) {
                caseAreaState = JSON.parse(caseFile.responseText);
            }
        }
    };
    caseFile.send(null);

    let newsFile = new XMLHttpRequest();
    newsFile.open("GET", `${process.env.PUBLIC_URL}/state_data/news_state.json`, false);        
    newsFile.onreadystatechange = () => {
        if (newsFile.readyState === 4) {                
            if (newsFile.status === 200 || newsFile.status === 0) {
                toggleListToken = JSON.parse(newsFile.responseText);
            }
        }
    };
    newsFile.send(null);

    let rawFile = new XMLHttpRequest();
    rawFile.open("GET",`${process.env.PUBLIC_URL}/state_data/product_state.json`,false);
    rawFile.onreadystatechange = () => {
        if (rawFile.readyState === 4) {                
            if (rawFile.status === 200 || rawFile.status === 0) {
                let arr_pc : Array<ProductItem> = [];
                let arr_tab : Array<ProductItem> = [];
                let arr_sm : Array<ProductItem>= [];
                let jsonData = JSON.parse(rawFile.responseText);
                for(let i = 0; i < jsonData.length; i++){
                    if(i%4 === 0 && i !== 0){
                        productsPC.push(arr_pc);
                        arr_pc = [];
                    }
                    if(i%3 === 0 && i !== 0){
                        productsTab.push(arr_tab);
                        arr_tab = [];
                    }
                    if(i%2 === 0 && i !== 0){
                        productsSm.push(arr_sm);
                        arr_sm = [];
                    }
                    arr_pc.push(jsonData[i]);
                    arr_tab.push(jsonData[i]);
                    arr_sm.push(jsonData[i]);
                }
                if(arr_pc.length > 0)productsPC.push(arr_pc);
                if(arr_tab.length > 0)productsTab.push(arr_tab);
                if(arr_sm.length > 0)productsSm.push(arr_sm);
            }
        }
    };
    rawFile.send(null);

    const leftSlider = () => {
        setPointerState(false);
        setMainRighttSlide(false);
        setMainLeftAnim(true);
        let numHolder = 0;

        if(currentProduct === 0){
            setNextProduct(productsPC.length-1);
            numHolder = productsPC.length-1;
        }else{
            setNextProduct(currentProduct-1);
            numHolder = currentProduct-1;
        }
        setTimeout(function(){
            setSubLeftSlide(true);
            setSubLeftAnim(true);
            setMainLeftAnim(false);
            setMainLeftSlide(false);
            setCurrentProduct(numHolder);
        },690);
        setTimeout(function(){
            setSubLeftSlide(false);
            setSubLeftAnim(false);
            setMainLeftSlide(true);
            setMainRighttSlide(true);
            setPointerState(true);
        },1500);
    }
    const rightSlider = () => {
        setPointerState(false);
        setMainLeftSlide(false);
        setMainRighttAnim(true);
        let numHolder = 0;
        if(currentProduct === (productsPC.length-1)){
            setNextProduct(0);
        }else{
            setNextProduct(currentProduct+1);
            numHolder = currentProduct+1;
        }
        setTimeout(function(){
            setSubRightSlide(true);
            setSubRightAnim(true);
            setMainRighttAnim(false);
            setMainRighttSlide(false);
            setCurrentProduct(numHolder);
        },690);
        setTimeout(function(){
            setSubRightSlide(false);
            setSubRightAnim(false);
            setMainRighttSlide(true);
            setMainLeftSlide(true);
            setPointerState(true);
        },1500);
    }
    const upFadeTab = () => {
        setPointerState(false);
        setCenterDisplay(false);
        setUpDisplay(true);
        setMainUpFade(true);
        setTimeout(function(){
            if(currentProductTab === 0){
                setCurrentProductTab(productsTab.length-1);
            }else{
                setCurrentProductTab(currentProductTab-1);
            }
            setUpDisplay(false);
            setCenterDisplay(true);
            setPointerState(true);
        },690);
    }
    const downFadeTab = () => {   
        setPointerState(false);     
        setCenterDisplay(false);
        setDownDisplay(true);
        setMainDownFade(true);
        setTimeout(function(){
            if(currentProductTab === (productsTab.length-1)){
                setCurrentProductTab(0);
            }else{
                setCurrentProductTab(currentProductTab+1);
            }
            setDownDisplay(false);
            setCenterDisplay(true);
            setPointerState(true);
        },690);

    }
    const upFadeSm = () => {
        setPointerState(false);
        setCenterDisplay(false);
        setUpDisplay(true);
        setMainUpFade(true);
        setTimeout(function(){
            if(currentProductSm === 0){
                setCurrentProductSm(productsSm.length-1);
            }else{
                setCurrentProductSm(currentProductSm-1);
            }
            setUpDisplay(false);
            setCenterDisplay(true);
            setPointerState(true);
        },690);
    }
    const downFadeSm = () => {  
        setPointerState(false);      
        setCenterDisplay(false);
        setDownDisplay(true);
        setMainDownFade(true);
        setTimeout(function(){
            if(currentProductSm === (productsSm.length-1)){
                setCurrentProductSm(0);
            }else{
                setCurrentProductSm(currentProductSm+1);
            }
            setDownDisplay(false);
            setCenterDisplay(true);
            setPointerState(true);
        },690);
    }

    const toggleListOpen = () => {
        if(newsToggleFlag){
            setNewsToggleFlag(false);
        }else{
            setNewsToggleFlag(true);
        }
    }

    const checkInputTypeNum = (input:any) => {
        if (input.currentTarget.value.match(/^[0-9]+$/) || input.currentTarget.value === ""){
            setErrorPhone("");
            if(!errorEmail)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorPhone("電話番号は半角数字で入力してください。");
            input.focus();
            return;
        }
    }
    const checkInputTypeEmail = (input:any) => {
        if (input.currentTarget.value.match(/.+@.+\..+/) || input.currentTarget.value === ""){
            setErrorEmail("");
            if(!errorPhone)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorEmail("メールアドレスを正しい形式で入力してください。");
            input.focus();
            return;
        }
    }
    
    //20240414 HIRAKI,T reCAPTCHAv3 Add Begin
    // reCAPTCHA v3のサイトキーを定義
    const SITE_KEY = '6LeO8K8pAAAAAMl2M8CwkFYe43qF2oikypaF4uvg';

    // reCAPTCHA v3 APIをページにロード
    const loadReCaptcha = () => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
        script.async = true;
        document.head.appendChild(script);
    };
    //20240414 HIRAKI,T reCAPTCHAv3 Add End

    const inquiryPost = () => {
        grecaptcha.ready(() => {//20240414 HIRAKI,T reCAPTCHAv3 Add
            grecaptcha.execute(SITE_KEY, {action: 'submit'}).then((token: string) => {//20240414 HIRAKI,T reCAPTCHAv3 Add
                // ここでフォームデータを収集
                let name : any = document.getElementById("at_inquiry_name");
                let email : any = document.getElementById("at_inquiry_emailaddress");
                let company : any = document.getElementById("at_inquiry_companyname");
                let phoneNumber : any = document.getElementById("at_inquiry_phonenumber");
                let type : any = document.getElementById("at_inquiry_type");
                let text : any = document.getElementById("at_inquiry_comment");
                let formData = new FormData();
                formData.append("fullname",name.value);
                formData.append("emailaddress",email.value);
                formData.append("companyname",company.value);
                formData.append("phonenumber",phoneNumber.value);
                formData.append("inquirytype",type.value);
                formData.append("comment",text.value);
                formData.append("pagetype","1");
                formData.append("recaptcha_token", token); // トークンをフォームデータに追
                const data = new URLSearchParams();
                formData.forEach((val,key) => {
                    data.append(key,String(val));
                });
                fetch("https://5171452.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=1363&deploy=2&compid=5171452&h=12842f5771b0a99cac09", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: data
                }).then((res) => {
                    alert("送信完了しました。");
                });
                setPostFlag(true);
            });//20240414 HIRAKI,T reCAPTCHAv3 Add
        });//20240414 HIRAKI,T reCAPTCHAv3 Add
    }
    //20240414 HIRAKI,T reCAPTCHAv3 Add Begin
    // reCAPTCHAの読み込み
    loadReCaptcha();
    //20240414 HIRAKI,T reCAPTCHAv3 Add End

    const redirectHome = () => {
        let name : any = document.getElementById("at_inquiry_name");
        let email : any = document.getElementById("at_inquiry_emailaddress");
        let company : any = document.getElementById("at_inquiry_companyname");
        let phoneNumber : any = document.getElementById("at_inquiry_phonenumber");
        let type : any = document.getElementById("at_inquiry_type");
        let text : any = document.getElementById("at_inquiry_comment");
        setPostFlag(false);
        setSubmitButtonColor("#89c3eb");
        setSubmitButtonPoint("none");
        name.value = "";
        email.value = "";
        company.value = "";
        company.value = "";
        phoneNumber.value = "";
        type.value = "";
        text.value = "";
    }

    const ToggleListReduce = () => {
        const rows = [];
        let x = 0;
        for (let i = Object.keys(toggleListToken).length; i > 0; i--) {
            if(x < 3){
                rows.push(
                    <NewsGridRow key={i}>
                    <NewsGridBlock>{toggleListToken["news"+i].category}</NewsGridBlock>
                    <NewsGridBlock></NewsGridBlock>
                    <NewsGridBlock>{toggleListToken["news"+i].date}</NewsGridBlock>
                    <NewsGridBlock></NewsGridBlock>
                    <NewsGridBlock><LinkTo to={"/news"+i}>{toggleListToken["news"+i].title}</LinkTo></NewsGridBlock>
                    </NewsGridRow>
                );
            }
            x++;
        }
        return <>{rows}</>;
    }
    const ToggleAllList = () => {
        const rows = [];
        for (let i = Object.keys(toggleListToken).length; i > 0; i--) {
            rows.push(
                    <NewsGridRow key={i}>
                    <NewsGridBlock>{toggleListToken["news"+i].category}</NewsGridBlock>
                    <NewsGridBlock></NewsGridBlock>
                    <NewsGridBlock>{toggleListToken["news"+i].date}</NewsGridBlock>
                    <NewsGridBlock></NewsGridBlock>
                    <NewsGridBlock><LinkTo to={"/news"+i}>{toggleListToken["news"+i].title}</LinkTo></NewsGridBlock>
                    </NewsGridRow>
            );
        }
        return <>{rows}</>;
    }

    const inquiryFillCheck = (e:any) => {
        if(inquiry_ref_name.current.value && inquiry_ref_email.current.value.match(/.+@.+\..+/) && inquiry_ref_companyName.current.value &&
        inquiry_ref_phone.current.value.match(/^[0-9]+$/) && inquiry_ref_type.current.value && inquiry_ref_comment.current.value){
            setSubmitButtonColor("#1e90ff");
            setSubmitButtonPoint("auto");
        }else{
            setSubmitButtonColor("#89c3eb");
            setSubmitButtonPoint("none");
        }
    }

    return(
        <>
            <AlertCurtain postFlag={postFlag}></AlertCurtain>
            <InquiryAlertArea postFlag={postFlag}>
                <InquiryAlertImg src={CheckMarkImg}/>
                <InquiryAlert>お問合せを確かに承りました。</InquiryAlert>
                <InquiryAlertButton onClick={(e) => {redirectHome();}}>OK</InquiryAlertButton>
            </InquiryAlertArea>
            <TopImgWrapper>
                <TopImgStyle src={`${process.env.PUBLIC_URL}/home/home_top.webp`}/>
            </TopImgWrapper>
            <NsLogoWrapper>
                <NsLogoImgStyle src={`${process.env.PUBLIC_URL}/home/oracle_nslogo.webp`}/>
            </NsLogoWrapper>
            <Section>
                <SectionExplanation>
                    <br/>経営と現場のお悩みに迅速に対応し、最適化を実現する<br/><br/>
                    <ServiceCompNameEmp><b>​株式会社SuiteUP</b></ServiceCompNameEmp>
                    <br/><br/>
                    私たち株式会社SuiteUPは、お客様の多様なビジネスに合わせた幅広いコンサルティングサービスを提供しています。<br/>
                    中小企業からグローバル企業に至るまで、お客様のビジネスを成功に導くお手伝いをしております。<br/>
                    私たちのサービスやチームの強みについてもっとお知りになりたい方は、お気軽にお問い合わせください。<br/>
                </SectionExplanation>
                <SectionTitle id="service_section">サービス</SectionTitle>
                <ServiceListArea>
                    <ServiceListWrapper>
                        <ServiceContainer>
                            <ServiceIcon src={`${process.env.PUBLIC_URL}/home/service1.webp`}/>
                            <ServiceName>NetSuite<br/>導入コンサルティング</ServiceName>
                            <ServiceSub>お客様のビジネスの成長のために</ServiceSub>
                            <ServiceExplanation>
                                経営と現場の最適化、それがビジネス拡張の鍵であることは誰しもご理解しています。<br/>
                                弊社は、外部、そしてITの視点からNetSuiteというERPを活用し、お客様環境の最適化を実現します。
                            </ServiceExplanation>
                        </ServiceContainer>
                        <ServiceContainer>
                            <ServiceIcon src={`${process.env.PUBLIC_URL}/home/service0.webp`}/>
                            <ServiceName>SaaS連携<br/>ソリューション</ServiceName>
                            <ServiceSub>SaaS連携で現場業務を支援</ServiceSub>
                            <ServiceExplanation>FSLink™️をはじめとする、弊社独自のSaaS連携ソリューションで、現場の業務負荷を大幅に低減します。</ServiceExplanation>
                        </ServiceContainer>
                        <ServiceContainer>
                            <ServiceIcon src={`${process.env.PUBLIC_URL}/home/service2.webp`}/>
                            <ServiceName>NetSuite<br/>アドオン開発・提供</ServiceName>
                            <ServiceSub>NetSuiteをより力強く</ServiceSub>
                            <ServiceExplanation>FSLink™️をはじめとする、弊社独自のSaaS連携ソリューションで、現場の業務負荷を大幅に低減します。</ServiceExplanation>
                        </ServiceContainer>
                        <ServiceContainer>
                            <ServiceIcon src={`${process.env.PUBLIC_URL}/home/service3.webp`}/>
                            <ServiceName>ICT戦略立案<br/>環境最適化支援</ServiceName>
                            <ServiceSub>ICT環境の恒久的な整備</ServiceSub>
                            <ServiceExplanation>当社のサービスでは、最新のツールやグローバルな人材を活用して、移り変わりの早い現在のビジネスシーンを生き延びるための経営戦略をご提案します。さらなる詳細につきましては、お気軽にお問い合わせください。</ServiceExplanation>
                        </ServiceContainer>
                    </ServiceListWrapper>
                </ServiceListArea>
            </Section>
            <SectionSpacer/>
            <Section>
                <SectionTitle id="product_section">プロダクト</SectionTitle>
                <SectionExplanation><br/>弊社が開発した、SaaS連携ソリューションや、NetSuiteのアドオンモジュールが登録されています。<br/>現場業務効率が格段に向上します。<br/></SectionExplanation>
                <ProductListArea>
                    <ProductLeftArrow onClick={leftSlider} pointer={pointerState}></ProductLeftArrow>
                    <TabletProductUpArrow onClick={upFadeTab} pointer={pointerState}></TabletProductUpArrow>
                    <PhoneProductUpArrow onClick={upFadeSm} pointer={pointerState}></PhoneProductUpArrow>
                    <PhoneProductArrowExp>クリックで表示が変わります</PhoneProductArrowExp>
                    <ProductListWrapper>
                        <PhoneProductSliderM anim={centerDisplay} display={centerDisplay.toString()}>
                            {
                                productsSm[currentProductSm][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductLinkTo to={productsSm[currentProductSm][0].url}>
                                        <ProductIconWrapper><ProductIcon src={productsSm[currentProductSm][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsSm[currentProductSm][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                        <ProductName>{productsSm[currentProductSm][0].name}</ProductName>
                                        <ProductElement>{productsSm[currentProductSm][0].element}</ProductElement>
                                    </ProductLinkTo>
                                </ProductContainer>
                            }
                            {
                                productsSm[currentProductSm][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductLinkTo to={productsSm[currentProductSm][1].url}>
                                        <ProductIconWrapper><ProductIcon src={productsSm[currentProductSm][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsSm[currentProductSm][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                        <ProductName>{productsSm[currentProductSm][1].name}</ProductName>
                                        <ProductElement>{productsSm[currentProductSm][1].element}</ProductElement>
                                    </ProductLinkTo>
                                </ProductContainer>
                            }
                        </PhoneProductSliderM>
                        <PhoneProductSlider1 anim={mainUpFade} display={upDisplay.toString()}>
                            {
                                productsSm[currentProductSm][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsSm[currentProductSm][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsSm[currentProductSm][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsSm[currentProductSm][0].name}</ProductName>
                                    <ProductElement>{productsSm[currentProductSm][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsSm[currentProductSm][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsSm[currentProductSm][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsSm[currentProductSm][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsSm[currentProductSm][1].name}</ProductName>
                                    <ProductElement>{productsSm[currentProductSm][1].element}</ProductElement>
                                </ProductContainer>
                            }
                        </PhoneProductSlider1>
                        <PhoneProductSlider2 anim={mainDownFade} display={downDisplay.toString()}>
                            {
                                productsSm[currentProductSm][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsSm[currentProductSm][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsSm[currentProductSm][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsSm[currentProductSm][0].name}</ProductName>
                                    <ProductElement>{productsSm[currentProductSm][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsSm[currentProductSm][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsSm[currentProductSm][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsSm[currentProductSm][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsSm[currentProductSm][1].name}</ProductName>
                                    <ProductElement>{productsSm[currentProductSm][1].element}</ProductElement>
                                </ProductContainer>
                            }
                        </PhoneProductSlider2>
                        <TabletProductSliderM anim={mainUpFade} display={centerDisplay.toString()}>
                            {
                                productsTab[currentProductTab][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductLinkTo to={productsTab[currentProductTab][0].url}>
                                        <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                        <ProductName>{productsTab[currentProductTab][0].name}</ProductName>
                                        <ProductElement>{productsTab[currentProductTab][0].element}</ProductElement>
                                    </ProductLinkTo>
                                </ProductContainer>
                            }
                            {
                                productsTab[currentProductTab][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductLinkTo to={productsTab[currentProductTab][1].url}>
                                        <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                        <ProductName>{productsTab[currentProductTab][1].name}</ProductName>
                                        <ProductElement>{productsTab[currentProductTab][1].element}</ProductElement>
                                    </ProductLinkTo>
                                </ProductContainer>
                            }
                            {
                                productsTab[currentProductTab][2] &&
                                <ProductContainer conDisplay={conDisplay3}>
                                    <ProductLinkTo to={productsTab[currentProductTab][2].url}>
                                        <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                        <ProductName>{productsTab[currentProductTab][2].name}</ProductName>
                                        <ProductElement>{productsTab[currentProductTab][2].element}</ProductElement>
                                    </ProductLinkTo>
                                </ProductContainer>
                            }
                        </TabletProductSliderM>
                        <TabletProductSlider1 anim={mainUpFade} display={upDisplay.toString()}>
                            {
                                productsTab[currentProductTab][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsTab[currentProductTab][0].name}</ProductName>
                                    <ProductElement>{productsTab[currentProductTab][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsTab[currentProductTab][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsTab[currentProductTab][1].name}</ProductName>
                                    <ProductElement>{productsTab[currentProductTab][1].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsTab[currentProductTab][2] &&
                                <ProductContainer conDisplay={conDisplay3}>
                                    <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsTab[currentProductTab][2].name}</ProductName>
                                    <ProductElement>{productsTab[currentProductTab][2].element}</ProductElement>
                                </ProductContainer>
                            }
                        </TabletProductSlider1>
                        <TabletProductSlider2 anim={mainDownFade} display={downDisplay.toString()}>
                            {
                                productsTab[currentProductTab][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsTab[currentProductTab][0].name}</ProductName>
                                    <ProductElement>{productsTab[currentProductTab][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsTab[currentProductTab][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsTab[currentProductTab][1].name}</ProductName>
                                    <ProductElement>{productsTab[currentProductTab][1].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsTab[currentProductTab][2] &&
                                <ProductContainer conDisplay={conDisplay3}>
                                    <ProductIconWrapper><ProductIcon src={productsTab[currentProductTab][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsTab[currentProductTab][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsTab[currentProductTab][2].name}</ProductName>
                                    <ProductElement>{productsTab[currentProductTab][2].element}</ProductElement>
                                </ProductContainer>
                            }
                        </TabletProductSlider2>
                        <MProductSlider1 anim={mainLeftAnim} display={mainLeftSlide.toString()}>
                                {
                                    productsPC[currentProduct][0] &&
                                    <ProductContainer conDisplay={conDisplay1}>
                                        <ProductLinkTo to={productsPC[currentProduct][0].url}>
                                            <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                            <ProductName>{productsPC[currentProduct][0].name}</ProductName>
                                            <ProductElement>{productsPC[currentProduct][0].element}</ProductElement>
                                        </ProductLinkTo>
                                    </ProductContainer>
                                }
                                {
                                    productsPC[currentProduct][1] &&
                                    <ProductContainer conDisplay={conDisplay2}>
                                        <ProductLinkTo to={productsPC[currentProduct][1].url}>
                                            <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                            <ProductName>{productsPC[currentProduct][1].name}</ProductName>
                                            <ProductElement>{productsPC[currentProduct][1].element}</ProductElement>
                                        </ProductLinkTo>
                                    </ProductContainer>
                                }
                                {
                                    productsPC[currentProduct][2] &&
                                    <ProductContainer conDisplay={conDisplay3}>
                                        <ProductLinkTo to={productsPC[currentProduct][2].url}>
                                            <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                            <ProductName>{productsPC[currentProduct][2].name}</ProductName>
                                            <ProductElement>{productsPC[currentProduct][2].element}</ProductElement>
                                        </ProductLinkTo>
                                    </ProductContainer>
                                }
                                {
                                    productsPC[currentProduct][3] &&
                                    <ProductContainer conDisplay={conDisplay4}>
                                        <ProductLinkTo to={productsPC[currentProduct][3].url}>
                                            <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][3].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][3].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                            <ProductName>{productsPC[currentProduct][3].name}</ProductName>
                                            <ProductElement>{productsPC[currentProduct][3].element}</ProductElement>
                                        </ProductLinkTo>
                                    </ProductContainer>
                                }
                        </MProductSlider1>
                        <MProductSlider2 anim={mainRightAnim} display={mainRightSlide.toString()}>
                            {
                                productsPC[currentProduct][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[currentProduct][0].name}</ProductName>
                                    <ProductElement>{productsPC[currentProduct][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[currentProduct][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[currentProduct][1].name}</ProductName>
                                    <ProductElement>{productsPC[currentProduct][1].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[currentProduct][2] &&
                                <ProductContainer conDisplay={conDisplay3}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[currentProduct][2].name}</ProductName>
                                    <ProductElement>{productsPC[currentProduct][2].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[currentProduct][3] &&
                                <ProductContainer conDisplay={conDisplay4}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[currentProduct][3].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[currentProduct][3].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[currentProduct][3].name}</ProductName>
                                    <ProductElement>{productsPC[currentProduct][3].element}</ProductElement>
                                </ProductContainer>
                            }
                        </MProductSlider2>
                        <SubProductSlider1 display={subLeftSlide.toString()} anim={subLeftAnim}>
                            {
                                productsPC[nextProduct][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][0].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[nextProduct][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][1].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][1].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[nextProduct][2] &&
                                <ProductContainer conDisplay={conDisplay3}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][2].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][2].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[nextProduct][3] &&
                                <ProductContainer conDisplay={conDisplay4}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][3].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][3].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][3].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][3].element}</ProductElement>
                                </ProductContainer>
                            }
                        </SubProductSlider1>
                        <SubProductSlider2 display={subRightSlide.toString()} anim={subRightAnim}>
                            {
                                productsPC[nextProduct][0] &&
                                <ProductContainer conDisplay={conDisplay1}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][0].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][0].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][0].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][0].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[nextProduct][1] &&
                                <ProductContainer conDisplay={conDisplay2}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][1].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][1].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][1].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][1].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[nextProduct][2] &&
                                <ProductContainer conDisplay={conDisplay3}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][2].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][2].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][2].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][2].element}</ProductElement>
                                </ProductContainer>
                            }
                            {
                                productsPC[nextProduct][3] &&
                                <ProductContainer conDisplay={conDisplay4}>
                                    <ProductIconWrapper><ProductIcon src={productsPC[nextProduct][3].img === "true"?`${process.env.PUBLIC_URL}/product/image/${productsPC[nextProduct][3].url}.webp`:`${process.env.PUBLIC_URL}/common/noimage.webp`}/></ProductIconWrapper>
                                    <ProductName>{productsPC[nextProduct][3].name}</ProductName>
                                    <ProductElement>{productsPC[nextProduct][3].element}</ProductElement>
                                </ProductContainer>
                            }
                        </SubProductSlider2>
                    </ProductListWrapper>
                    <ProductRightArrow onClick={rightSlider} pointer={pointerState}></ProductRightArrow>
                    <PhoneProductArrowExp>クリックで表示が変わります</PhoneProductArrowExp>
                    <TabletProductDownArrow onClick={downFadeTab} pointer={pointerState}></TabletProductDownArrow>
                    <PhoneProductDownArrow onClick={downFadeSm} pointer={pointerState}></PhoneProductDownArrow>
                </ProductListArea>
            </Section>
            <SectionSpacer/>
            <Section>
            <SectionTitle id="instance_section">事例紹介</SectionTitle>
                <SectionExplanation>
                様々な業種、規模のお客様が、弊社サービスを利用されています。
                </SectionExplanation>
                <InstanceListArea>
                    <InstanceListWrapper>
                        <InstanceExpContainer1 style={{marginRight:"auto"}}>
                            <InstanceContainerCompany style={{fontWeight:"bold"}}>
                                {
                                    caseAreaState[0].companyname.split("\n").map((item: any, index: any) => {
                                        // <></> は key を設定できないので、<span /> を使う
                                        return (
                                            <span key={index}>{item}<br /></span>
                                        );
                                    })
                                }
                            </InstanceContainerCompany>
                            <InstanceContainerTitle>
                                {
                                    caseAreaState[0].topline.split("\n").map((item: any, index: any) => {
                                        // <></> は key を設定できないので、<span /> を使う
                                        return (
                                            <span key={index}>{item}<br /></span>
                                        );
                                    })
                                }
                            </InstanceContainerTitle>
                            <InstanceContainerText>
                                {
                                    caseAreaState[0].subline.split("\n").map((item: any, index: any) => {
                                        // <></> は key を設定できないので、<span /> を使う
                                        return (
                                            <span key={index}>{item}<br /></span>
                                        );
                                    })
                                }
                            </InstanceContainerText>
                            <span style={{color:"#000"}}>＞ </span><LinkTo style={{color:"#000"}} to={"/case1"}>この事例を見る</LinkTo>
                        </InstanceExpContainer1>
                        <InstanceImg1 src={`${process.env.PUBLIC_URL}/home/home_case0.webp`} style={{right:"0px"}}/>
                    </InstanceListWrapper>
                </InstanceListArea>
                <br/>
                <InstanceListArea>
                    <InstanceListWrapper>
                        <InstanceExpContainer2 style={{marginLeft:"auto"}}>
                            <InstanceContainerCompany style={{fontWeight:"bold"}}>
                                {
                                    caseAreaState[1].companyname.split("\n").map((item: any, index: any) => {
                                        // <></> は key を設定できないので、<span /> を使う
                                        return (
                                            <span key={index}>{item}<br /></span>
                                        );
                                    })
                                }
                            </InstanceContainerCompany>
                            <InstanceContainerTitle>
                                {
                                    caseAreaState[1].topline.split("\n").map((item: any, index: any) => {
                                        // <></> は key を設定できないので、<span /> を使う
                                        return (
                                            <span key={index}>{item}<br /></span>
                                        );
                                    })
                                }
                            </InstanceContainerTitle>
                            <InstanceContainerText>
                                {
                                    caseAreaState[1].subline.split("\n").map((item: any, index: any) => {
                                        // <></> は key を設定できないので、<span /> を使う
                                        return (
                                            <span key={index}>{item}<br /></span>
                                        );
                                    })
                                }
                            </InstanceContainerText>
                            <span style={{color:"#000"}}>＞ </span><LinkTo style={{color:"#000"}} to={"/case2"}>この事例を見る</LinkTo>
                        </InstanceExpContainer2>
                        <InstanceImg2 src={`${process.env.PUBLIC_URL}/home/home_case1.webp`} style={{left:"0px"}}/>
                    </InstanceListWrapper>
                </InstanceListArea>
            </Section>
            <SectionSpacer/>
            <Section>
                <SectionTitle id="news_section">What's New</SectionTitle>
                <NewsArea>
                    <NewsGridArea>
                        {
                            newsToggleFlag?<ToggleAllList/>:<ToggleListReduce/>
                        }
                    </NewsGridArea>
                </NewsArea>
                <NewsGridToggleArea>
                    <NewsGridToggle onClick={toggleListOpen}>＜＜{newsToggleFlag?"閉じる":"もっと見る"}＞＞</NewsGridToggle>
                    <p>（クリックで全ての更新履歴が表示されます）</p>
                </NewsGridToggleArea>
            </Section>
            <SectionSpacer/>
            <Section>
                <SectionTitle id="inquiry_section">お問合せ</SectionTitle>
                {/* <form method="POST" action="https://5171452.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=1363&deploy=2&compid=5171452&h=12842f5771b0a99cac09"> */}
                <InquiryWrapper>
                    <InquiryErrorArea>
                        <InquiryErrorImg src={BatsuMarkImg} errorFlag={errorFlag} />
                        <InquiryError>
                            <p style={{margin:"0px"}}>{errorEmail}</p>
                            <p style={{margin:"0px"}}>{errorPhone}</p>
                        </InquiryError>
                    </InquiryErrorArea>
                    <InquiryArea>
                        <InquiryGridArea>
                            <InquiryGridBlock ref={(inquiry_ref_name)} name="fullname" placeholder="御芳名 *" id="at_inquiry_name" type="text" onChange={(e) => inquiryFillCheck(e)}/>
                            <span></span>
                            <InquiryGridBlock ref={(inquiry_ref_email)} name="emailaddress" placeholder="Email *" id="at_inquiry_emailaddress" type="email" pattern=".+@.+\..+" onChange={(e) => inquiryFillCheck(e)} onBlur={(e) => {checkInputTypeEmail(e);}}/>
                            <InquiryGridBlock ref={(inquiry_ref_companyName)} name="companyname" style={{gridColumn:"span 3"}} placeholder="貴社名 *" id="at_inquiry_companyname" type="text" onChange={(e) => inquiryFillCheck(e)}/>
                            <InquiryGridBlock ref={(inquiry_ref_phone)} name="phonenumber" placeholder="お電話番号 *" id="at_inquiry_phonenumber" pattern="[0-9]*" onChange={(e) => {inquiryFillCheck(e);checkInputTypeNum(e);}}/>
                            <span></span>
                            <InquiryType defaultValue={""} ref={(inquiry_ref_type)} name="inquirytype" id="at_inquiry_type" onChange={(e) => inquiryFillCheck(e)}>
                                <InquiryTypeOption value="">お問合せ概要 *</InquiryTypeOption>
                                <InquiryTypeOption value="1">NetSuiteについてのご相談</InquiryTypeOption>
                                <InquiryTypeOption value="2">お問合せする方の営業活動</InquiryTypeOption>
                                <InquiryTypeOption value="3">NetSuite以外の情報収集</InquiryTypeOption>
                                <InquiryTypeOption value="4">その他</InquiryTypeOption>
                            </InquiryType>
                            <InquiryTextArea ref={(inquiry_ref_comment)} name="comment" style={{gridColumn:"span 3",gridRow:"span 3",verticalAlign:"top"}} placeholder="お問合せ内容 *" id="at_inquiry_comment" onChange={(e) => inquiryFillCheck(e)}/>
                        </InquiryGridArea>
                        <GoogleMapField>
                            <GoogleMap src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12960.702265941594!2d139.773477!3d35.697297!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa44a67bf538415a8!2z5qCq5byP5Lya56S-44Ko44Kk44Ki44Oz44OJ44OG44Kj!5e0!3m2!1sja!2sjp!4v1662083988198!5m2!1sja!2sjp"></GoogleMap>
                                <InquiryAddress>〒101-0025 東京都千代田区神田佐久間町1-8-4アルテール秋葉原708　</InquiryAddress>
                        </GoogleMapField>
                    </InquiryArea>
                    <InquirySubmitButtonArea>
                        <InquirySubmitButton submitButtonPoint={submitButtonPoint} submitButtonColor={submitButtonColor} type="submit" value="Submit" onClick={inquiryPost}/>
                    </InquirySubmitButtonArea>
                </InquiryWrapper>
                {/* </form> */}
            </Section>
        </>
    )
}