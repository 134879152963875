import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CheckMarkImg from "../assets/checkmark.webp"
import BatsuMarkImg from "../assets/batsumark.webp"
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-S76879P0PF");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

const GroundWrapper = styled.div`
    width:100%;
    height:100%;
    max-width:1200px;
    margin-left:auto;
    margin-right:auto;
`
const HistoryArea = styled.div`
    height:70px;
    @media (max-width: 768px) {
        height:50px;
    }
    @media (max-width: 375px) {
        height:30px;
    }
`
const History = styled(Link)`
    text-decoration:none;
    @media (prefers-color-scheme: dark) {
        color: #FFF;
    }
    @media (prefers-color-scheme: light) {
        color: #000;
    }
`
const RecruitArticleWrapper = styled.div`
    max-width:1000px;
    width:80%;
    margin-left:auto;
    margin-right:auto;
    @media (max-width: 375px) {
        width:100%;
    }
`
const ArticleTitle = styled.p`
    font-weight:bold;
    font-size:40px;
    text-align:center;
    @media (max-width: 768px) {
        font-size:30px;
    }
    @media (max-width: 375px) {
        font-size:22px;
    }
`
const OverviewContainer = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content:center;
    justify-content:center;
    flex-wrap:wrap;
`
const Location = styled.p`
    width:70%;
    font-weight:bold;
    font-size:22px;
    @media (max-width: 768px) {
        width:60%;
        font-size:18px;
        margin-right:10%;
    }
    @media (max-width: 375px) {
        font-size:15px;
    }
`
const Capacity = styled.p`
    width:30%;
    font-weight:bold;
    font-size:22px;
    @media (max-width: 768px) {
        font-size:18px;
    }
    @media (max-width: 375px) {
        font-size:15px;
    }
`
const Overview = styled.p`
    width:100%;
`

const DetailSectionWrapper = styled.div`
    display:grid;
    grid-template-columns:20% 5% 75%;
    gap:20px;
    @media (max-width: 375px) {
        
        column-gap:0px;
    }
`
const DetailSectionSpacer = styled.span`
`
const DetailTitle = styled.div`
    margin-right:10%;
    font-size:20px;
    @media (max-width: 768px) {
        font-size:16px;
    }
    @media (max-width: 375px) {
        font-size:15px;
    }
`
const DetailSubTitle = styled.div`
    font-size:20px;
    @media (max-width: 768px) {
        font-size:16px;
    }
    @media (max-width: 375px) {
        font-size:15px;
    }
`
const DetailExplanation = styled.div`
`

const InquiryFormWrapper1 = styled.div`
    display:grid;
    grid-template-columns:20% 20% 20% 20%;
    grid-auto-rows:50px;
    gap:20px;
    background:#d3d3d3;
    color:#000;
    width:100%;
    justify-content:center;
    -webkit-justify-content:center;
    @media (max-width: 768px) {
        grid-auto-rows:40px;
    }
    @media (max-width: 375px) {
        grid-auto-rows:30px;
        gap:10px;
    }
`
const InquiryTitle = styled.div`
    grid-column:span 4;
    font-size:22px;
    font-weight:bold;
    text-align:center;
    @media (max-width: 375px) {
        font-size:18px;
    }
`
const InputTypeA = styled.input`
    border:0;
    grid-column:span 2;
    ::placeholder{
        font-size:14px;
        font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
            'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
`
const InputTypeB = styled.input`
    border:0;
    ::placeholder{
        font-size:14px;
        font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
            'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
    @media (max-width: 768px) {
        grid-column:span 2;
    }
    @media (max-width: 375px) {
        grid-column:span 2;
    }
`
const FileInputLable = styled.label`
    width:100%;
    font-size:11px;
    grid-column:span 2;
    @media (max-width: 768px) {
        font-size:10px;
        grid-column:span 4;
    }
    @media (max-width: 375px) {
        font-size:9px;
        grid-column:span 4;
    }
`
const InquiryFormWrapper2 = styled.div`
    display:grid;
    grid-template-columns:25% 5% 5% 45%;
    grid-auto-rows:50px;
    gap:20px;
    padding-top:20px;
    background:#d3d3d3;
    color:#000;
    justify-content:center;
    -webkit-justify-content:center;
    @media (max-width: 768px) {
        grid-auto-rows:40px;
    }
    @media (max-width: 375px) {
        grid-auto-rows:30px;
        gap:10px;
    }
`
const CurrentSituation = styled.div`
    grid-column:span 2;
    @media (max-width: 768px) {
        grid-column:span 4;
    }
`
const ButtonArrange = styled.div`
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content:space-between;
    justify-content: space-between;
    @media (max-width: 768px) {
        -webkit-justify-content:start;
        justify-content: start;
    }
`
const MemoArea = styled.textarea`
    grid-column:span 2;
    grid-row:span 2;
    resize: none;
    border:0;
    ::placeholder{
        font-size:14px;
        font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
            'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        @media (max-width: 768px) {
            font-size:12px;
        }
        @media (max-width: 375px) {
            font-size:10px;
        }
    }
    @media (max-width: 768px) {
        grid-column:span 4;
    }
`
const AgreementMatters = styled.div`
    grid-column:span 4;
    grid-row:span 4;
    resize: none;
    overflow: auto;
    background:lightyellow;
    font-size:12px;
    @media (max-width: 768px) {
        grid-row:span 4;
        font-size:10px;
    }
    @media (max-width: 375px) {
        font-size:9px;
    }
`
const AgreementCheckbox = styled.label`
    grid-column:span 4;
`
const ButtonWrapper = styled.div`
    grid-column:span 4;
    text-align:center;
`
interface submitButtonProps {
    submitButtonColor:string,
    submitButtonPoint:string,
}
const InquirySubmitButton = styled.input<submitButtonProps>`
    text-align:center;
    width:300px;
    height:50px;
    font-size:20px;
    font-weight:bold;
    color:white;
    background:${(props) => props.submitButtonColor};
    pointer-events:${(props) => props.submitButtonPoint};
    border-radius:10px;
    border:0;
    cursor:pointer;
    @media (max-width: 768px) {
        font-size:20px;
        width:200px;
    }
    @media (max-width: 375px) {
        font-size:16px;
        width:100px;
    }
`
interface postMessageProps {
    postFlag:boolean
}
const AlertCurtain1 = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    position:fixed;

    width:100%;
    height:100%;
    z-index:299;
    background:#000;
    opacity:0.4;
`
const AlertCurtain2 = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    position:fixed;

    width:100%;
    height:100%;
    z-index: 299;
    background:#000;
    opacity:0.4;
`
const InquiryAlertArea = styled.div<postMessageProps>`
    display:${(props) => props.postFlag?"auto":"none"};
    top:calc(50% - 110px);
    right:calc(50% - 170px);
    position:fixed;
    width:250px;
    height:220px;
    padding-top:25px;
    padding-bottom:25px;
    padding-right:45px;
    padding-left:45px;
    background:#fff;
    z-index:300;
    border:1px solid black;
    text-align:center;
    border-radius:10px;
    @media (max-width: 768px) {
        top:calc(50% - 100px);
        right:calc(50% - 135px);
        width:200px;
        height:170px;
        padding-top:15px;
        padding-bottom:15px;
        padding-right:35px;
        padding-left:35px;
    }
    @media (max-width: 375px) {
        top:calc(50% - 75px);
        right:calc(50% - 100px);
        width:160px;
        height:120px;
        padding-top:10px;
        padding-bottom:20px;
        padding-right:20px;
        padding-left:20px;
    }
`
const InquiryAlertImg1 = styled.img`
    width:150px;
    @media (max-width: 768px) {
        width:120px;
    }
    @media (max-width: 375px) {
        width:70px;
    }
`
const InquiryAlertImg2 = styled.img`
    width:70px;
    @media (max-width: 768px) {
        width:55px;
        margin-top:12px;
        margin-bottom:12px;
    }
    @media (max-width: 375px) {
        width:35px;
        margin-top:10px;
        margin-bottom:10px;
    }
`
const InquiryAlert = styled.div`
    width:250px;
    font-weight:bold;
    margin-bottom:20px;
    @media (max-width: 768px) {
        width:200px;
    }
    @media (max-width: 375px) {
        width:160px;
    }
`
const InquiryAlertButton = styled.button`
    width:100px;
    padding:15px;
    font-weight:bold;
    color:white;
    background:#87cefa;
    border-radius:10px;
    border:0;
    cursor:pointer;
    :hover{
        background:#00bfff;
    }
    @media (max-width: 768px) {
        width:100px;
        padding:10px;
    }
    @media (max-width: 375px) {
        width:70px;
        padding:8px;
    }
`
const InquiryErrorArea = styled.div`
    grid-column:span 4;
    width:100%;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-justify-content: center; /* Safari */
    justify-content:center;
    text-align:left;
`
interface errorProps {
    errorFlag:boolean
}
const InquiryErrorImg = styled.img<errorProps>`
    display:${(props) => props.errorFlag?"auto":"none"};
    width:50px;
    margin-bottom:auto;
    margin-top:auto;
    margin-left:10px;
    margin-right:10px;
    @media (max-width: 768px) {
        width:40px;
    }
    @media (max-width: 375px) {
        width:30px;
    }
`
const InquiryError = styled.div`
    margin-bottom:auto;
    margin-top:auto;
`
const InquiryAddress = styled.select`
    border:0;
    font-size:14px;
    font-family:'meiryo',-apple-system, BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen',
                'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    @media (max-width: 768px) {
        font-size:12px;
    }
    @media (max-width: 375px) {
        font-size:10px;
        height:30px;
    }
`
const Prefecture =() => {
    return(
        <InquiryAddress id="at_inquiry_state" defaultValue={""}>
        <option value="">勤務希望場所</option>
        <option value="hokkaido">北海道</option>
        <option value="aomori">青森県</option>
        <option value="iwate">岩手県</option>
        <option value="miyagi">宮城県</option>
        <option value="akita">秋田県</option>
        <option value="yamagata">山形県</option>
        <option value="fukushima">福島県</option>
        <option value="ibaraki">茨城県</option>
        <option value="tochigi">栃木県</option>
        <option value="gunma">群馬県</option>
        <option value="saitama">埼玉県</option>
        <option value="chiba">千葉県</option>
        <option value="tokyo">東京都</option>
        <option value="kanagawa">神奈川県</option>
        <option value="niigata">新潟県</option>
        <option value="toyama">富山県</option>
        <option value="ishikawa">石川県</option>
        <option value="fukui">福井県</option>
        <option value="yamanashi">山梨県</option>
        <option value="nagano">長野県</option>
        <option value="gifu">岐阜県</option>
        <option value="shizuoka">静岡県</option>
        <option value="aichi">愛知県</option>
        <option value="mie">三重県</option>
        <option value="shiga">滋賀県</option>
        <option value="kyoto">京都府</option>
        <option value="osaka">大阪府</option>
        <option value="hyogo">兵庫県</option>
        <option value="nara">奈良県</option>
        <option value="wakayama">和歌山県</option>
        <option value="tottori">鳥取県</option>
        <option value="shimane">島根県</option>
        <option value="okayama">岡山県</option>
        <option value="hiroshima">広島県</option>
        <option value="yamaguchi">山口県</option>
        <option value="tokushima">徳島県</option>
        <option value="kagawa">香川県</option>
        <option value="ehime">愛媛県</option>
        <option value="kochi">高知県</option>
        <option value="fukuoka">福岡県</option>
        <option value="saga">佐賀県</option>
        <option value="nagasaki">長崎県</option>
        <option value="kumamoto">熊本県</option>
        <option value="oita">大分県</option>
        <option value="miyazaki">宮崎県</option>
        <option value="kagoshima">鹿児島県</option>
        <option value="okinawa">沖縄県</option>
        <option value="overseas">海外</option>
        </InquiryAddress>
    )
}
export const RecruitDetail = React.memo(() => {
    //inqury
    const inquiry_ref_firstname : any = React.createRef();
    const inquiry_ref_lastname : any = React.createRef();
    const inquiry_ref_age : any = React.createRef();
    const inquiry_ref_email : any = React.createRef();
    const [submitButtonColor,setSubmitButtonColor] : any = useState<string>("#89c3eb");
    const [submitButtonPoint,setSubmitButtonPoint] : any = useState<string>("none");
    const [errorFlag,setErrorFlag] : any = useState<boolean>(false);
    const [errorAge,setErrorAge] : any = useState<string>("");
    const [errorEmail,setErrorEmail] : any = useState<string>("");
    const [postSuccessFlag,setPostSuccessFlag] : any = useState<boolean>(false);
    const [postErrorFlag,setPostErrorFlag] : any = useState<boolean>(false);
    const [postErrorMes,setPostErrorMes] : any = useState<string>("");
    const sampleLocation : any = useLocation().pathname;
    const currentPageType : any = String(sampleLocation.replace("/","").replace("recruitdetail",""));
    let recruitList : any = "";

    const checkInputTypeNum = (input:any) => {

        if (input.currentTarget.value.match(/^[0-9]+$/) || input.currentTarget.value === ""){
            setErrorAge("");
            if(!errorEmail)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorAge("年齢は半角数字で入力してください。");
            input.focus();
            return;
        }
    }
    const checkInputTypeEmail = (input:any) => {
        if (input.currentTarget.value.match(/.+@.+\..+/) || input.currentTarget.value === ""){
            setErrorEmail("");
            if(!errorAge)setErrorFlag(false);
        }else{
            setErrorFlag(true);
            setErrorEmail("メールアドレスを正しい形式で入力してください。");
            input.focus();
            return;
        }
    }

    const inquiryPost = async () => {
        //20240414 HIRAKI,T reCAPTCHAv3 Add Begin
        // reCAPTCHA v3のサイトキーを定義
        const SITE_KEY = '6LeO8K8pAAAAAMl2M8CwkFYe43qF2oikypaF4uvg';
        //20240414 HIRAKI,T reCAPTCHAv3 Add End

        let lastName : any = document.getElementById("at_inquiry_lastname");
        let firstName : any = document.getElementById("at_inquiry_firstname");
        let age : any = document.getElementById("at_inquiry_age");
        let email : any = document.getElementById("at_inquiry_emailaddress");
        let cv : any = document.querySelector("#at_inquiry_cv");
        let resume : any = document.querySelector("#at_inquiry_resume");
        let working : any = document.getElementsByName("at_inquiry_working_status");
        let state  : any = document.getElementById("at_inquiry_state");
        let comment : any = document.getElementById("at_inquiry_comment");
        let consent : any = document.getElementById("at_inquiry_consent");
        let workingValue : string = ""
        
        for (let i = 0; i < working.length; i++) {
            if (working[i].checked){
                workingValue = String(working[i].value);
            }
        }
        let emptyCheckArr : any = {
            "御芳名(姓)":lastName.value,
            "御芳名(名)":firstName.value,
            "年齢":age.value,
            "Email":email.value
        };
        let errrorMes : any = "";
        for(let key in emptyCheckArr){
            if(!emptyCheckArr[key]){
                errrorMes += String(key) + ",";
            }
        }

        if(errrorMes){
            let errorStr = errrorMes.substring(0, errrorMes.length - 1);
            setPostErrorMes(errorStr+"は入力必須項目です。再度入力してから送信してください。");
            setPostErrorFlag(true);
            return;
        }
        if(!consent.checked){
            setPostErrorMes("同意事項にチェックをしてください。");
            setPostErrorFlag(true);
            return;
        }
        const sizeLimit = 1024 * 1024 * 1;
        try{
            if (cv.files[0].size > sizeLimit) {
                setPostErrorMes("職務経歴書のファイルサイズは1MB以下にして再度選択してください。");
                setPostErrorFlag(true);
                cv.value = '';
                return;
            }
        }catch(e){}
        try{
            if (resume.files[0].size > sizeLimit) {
                setPostErrorMes("履歴書のファイルサイズは1MB以下にして再度選択してください。");
                setPostErrorFlag(true);
                resume.value = '';
                return;
            }
        }catch(e){}
        let formData = new FormData();
        
        formData.append("lastname",lastName.value);
        formData.append("firstname",firstName.value);
        formData.append("age",age.value);
        formData.append("emailaddress",email.value);
        formData.append("working",workingValue);
        formData.append("state",state.value);
        formData.append("comment",comment.value);
        formData.append("occupation",currentPageType);
        formData.append("pagetype","3");
        try{
            formData.append("cvname",cv.files[0].name);
            await fileConverter(cv.files[0]).then((customJsonFile : any) => {
                formData.append("cv",customJsonFile.base64StringFile);
            });
        }catch(e){
            console.log(e);
        }
        try{
            formData.append("resumename",resume.files[0].name);
            await fileConverter(resume.files[0]).then((customJsonFile : any) => {
                formData.append("resume",customJsonFile.base64StringFile);
            });
        }catch(e){
            console.log(e);
        }

        //20240414 HIRAKI,T reCAPTCHAv3 Add Begin
        // reCAPTCHA v3 トークンを取得してフォームデータに追加
        const recaptchaToken = await grecaptcha.execute(SITE_KEY, { action: 'submit' });
        formData.append('recaptcha_token', recaptchaToken);
        //20240414 HIRAKI,T reCAPTCHAv3 Add End

        let data = new URLSearchParams();
        formData.forEach((val,key) => {
            // if(key === "cv"){
            //     data.append(key,val);
            // }else{
            // }
            data.append(key,String(val));
        });
        fetch("https://5171452.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=1363&deploy=2&compid=5171452&h=12842f5771b0a99cac09", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: data
        }).then((res) => {
            alert("送信完了しました。");

        });
        setPostSuccessFlag(true);
    }

    const fileConverter = (file:any) => {
        let base64StringFile : any= ""
        var reader : FileReader= new FileReader();
        return new Promise((resolve, reject) => {
            reader.readAsArrayBuffer(file);
            reader.onerror = () => { reader.abort(); new Error("Error parsing file");}
            //ファイル読込成功イベント
            reader.onload = function() {
                // var base64 : any = btoa(data_url_scheme);
                let bytes : any = Array.from(new Uint8Array(reader.result as ArrayBuffer));
                base64StringFile = btoa(bytes.map((item:any) => String.fromCharCode(item)).join(""));
                resolve({ 
                    bytes: bytes,
                    base64StringFile: base64StringFile
                });
            }
        });
    }

    let rawFile = new XMLHttpRequest();
    rawFile.open("GET", `${process.env.PUBLIC_URL}/recruit/recruit_detail${currentPageType}.json`, false);        
    rawFile.onreadystatechange = () => {
        if (rawFile.readyState === 4) {                
            if (rawFile.status === 200 || rawFile.status === 0) {
                recruitList = JSON.parse(rawFile.responseText);
            }
        }
    };
    rawFile.send(null);
    const inquiryFillCheck = (e:any) => {
        let consent : any = document.getElementById("at_inquiry_consent");

        if(inquiry_ref_firstname.current.value && inquiry_ref_lastname &&
         inquiry_ref_email.current.value.match(/.+@.+\..+/) && inquiry_ref_age.current.value.match(/^[0-9]+$/) && consent.checked){
            setSubmitButtonColor("#1e90ff");
            setSubmitButtonPoint("auto");
            setErrorFlag(false);
            setErrorEmail("");
            setErrorAge("");
        }else{
            setSubmitButtonColor("#89c3eb");
            setSubmitButtonPoint("none");
        }
        
    }
    const afterPost = () => {
        let lastName : any = document.getElementById("at_inquiry_lastname");
        let firstName : any = document.getElementById("at_inquiry_firstname");
        let age : any = document.getElementById("at_inquiry_age");
        let email : any = document.getElementById("at_inquiry_emailaddress");
        let cv : any = document.querySelector("#at_inquiry_cv");
        let resume : any = document.querySelector("#at_inquiry_resume");
        let working : any = document.getElementsByName("at_inquiry_working_status");
        let state  : any = document.getElementById("at_inquiry_state");
        let comment : any = document.getElementById("at_inquiry_comment");
        let consent : any = document.getElementById("at_inquiry_consent");
        for (let i = 0; i < working.length; i++) {
            if (working[i].checked){
                working[i].checked = false;
            }
        }
        console.log("in");
        setPostSuccessFlag(false);
        setSubmitButtonColor("#89c3eb");
        setSubmitButtonPoint("none");
        lastName.value = "";
        firstName.value = "";
        age.value = "";
        email.value = "";
        cv.value = "";
        resume.value = "";
        working.value = "";
        state.value = "";
        comment.value = "";
        consent.checked = false;
    }
    return(
        <>
            <AlertCurtain1 postFlag={postSuccessFlag}></AlertCurtain1>
            <AlertCurtain2 postFlag={postErrorFlag}></AlertCurtain2>
            <InquiryAlertArea postFlag={postSuccessFlag}>
                <InquiryAlertImg1 src={CheckMarkImg}/>
                <InquiryAlert>お問合せを確かに承りました。</InquiryAlert>
                <InquiryAlertButton onClick={(e) => {afterPost();}}>OK</InquiryAlertButton>
            </InquiryAlertArea>
            <InquiryAlertArea postFlag={postErrorFlag}>
                <InquiryAlertImg2 src={BatsuMarkImg}/>
                <InquiryAlert>{postErrorMes}</InquiryAlert>
                <InquiryAlertButton onClick={(e) => {setPostErrorFlag(false);setPostErrorMes("");}}>OK</InquiryAlertButton>
            </InquiryAlertArea>
            <GroundWrapper>
                <HistoryArea>
                    <History to={"/"}>ホーム / </History>
                    <History to={"/recruit"}>採用情報 / </History>
                    <History to={sampleLocation} dangerouslySetInnerHTML={{__html:recruitList.name}}></History>
                </HistoryArea>
                <RecruitArticleWrapper>
                    <ArticleTitle dangerouslySetInnerHTML={{__html:recruitList.name}}></ArticleTitle>
                    <OverviewContainer>
                        <Location>勤務地 : <span dangerouslySetInnerHTML={{__html:recruitList.location}}></span></Location>
                        <Capacity>募集人数 : <span dangerouslySetInnerHTML={{__html:recruitList.number}}></span></Capacity>
                        <Overview dangerouslySetInnerHTML={{__html:recruitList.content}}></Overview>
                    </OverviewContainer>
                    <br/><br/>
                    <DetailSectionWrapper>
                        <DetailTitle>募集要項</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation>
                            <DetailSubTitle>【必須要件】</DetailSubTitle>
                            <span dangerouslySetInnerHTML={{__html:recruitList.requirement_mandatory}}></span>
                        </DetailExplanation>
                        <DetailTitle></DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation>
                            <DetailSubTitle>【歓迎要件】</DetailSubTitle>
                            <span dangerouslySetInnerHTML={{__html:recruitList.requirement_better}}></span>
                        </DetailExplanation>
                        <DetailTitle></DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation>
                            <DetailSubTitle>【求める人物像】</DetailSubTitle>
                            <span dangerouslySetInnerHTML={{__html:recruitList.requirement_ideal}}></span>
                        </DetailExplanation>
                        <DetailTitle>年収レンジ</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation dangerouslySetInnerHTML={{__html:recruitList.income_range}}></DetailExplanation>
                        <DetailTitle>諸手当</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation dangerouslySetInnerHTML={{__html:recruitList.allowances}}></DetailExplanation>
                        <DetailTitle>昇給</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation dangerouslySetInnerHTML={{__html:recruitList.promotion}}></DetailExplanation>
                        <DetailTitle>勤務時間</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation dangerouslySetInnerHTML={{__html:recruitList.working_time}}></DetailExplanation>
                        <DetailTitle>休日休暇</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation dangerouslySetInnerHTML={{__html:recruitList.day_off}}></DetailExplanation>
                        <DetailTitle>選考プロセス</DetailTitle>
                        <DetailSectionSpacer></DetailSectionSpacer>
                        <DetailExplanation dangerouslySetInnerHTML={{__html:recruitList.employment_process}}></DetailExplanation>
                        <DetailSectionSpacer>　</DetailSectionSpacer>
                        <DetailSectionSpacer>　</DetailSectionSpacer>
                        <DetailSectionSpacer>　</DetailSectionSpacer>
                    </DetailSectionWrapper>
                    <InquiryFormWrapper1>
                        <InquiryErrorArea>
                        <InquiryErrorImg src={BatsuMarkImg} errorFlag={errorFlag} />
                        <InquiryError>
                            <p style={{margin:"0px"}}>{errorEmail}</p>
                            <p style={{margin:"0px"}}>{errorAge}</p>
                        </InquiryError>
                        </InquiryErrorArea>
                        <InquiryTitle>応募フォーム</InquiryTitle>
                        <InputTypeA ref={inquiry_ref_lastname} id="at_inquiry_lastname" placeholder="御芳名(姓) *" type="text" onChange={(e) => inquiryFillCheck(e)}/>
                        <InputTypeA ref={inquiry_ref_firstname} id="at_inquiry_firstname" placeholder="御芳名(名) *" type="text" onChange={(e) => inquiryFillCheck(e)}/>
                        <InputTypeB ref={inquiry_ref_age} id="at_inquiry_age" placeholder="年齢 *" type="text" pattern="[0-9]*" onChange={(e) => {inquiryFillCheck(e);checkInputTypeNum(e);}}/>
                        <InputTypeB ref={inquiry_ref_email} id="at_inquiry_emailaddress" placeholder="Email *" type="email" onChange={(e) => inquiryFillCheck(e)} onBlur={checkInputTypeEmail}/><br />
                        <FileInputLable><InputTypeA id="at_inquiry_resume" type="file" accept=".docx,.xlsx,.pdf,.jpeg,.jpg"/><br/>履歴書アップロード（1MBまで)</FileInputLable>
                        <FileInputLable><InputTypeA id="at_inquiry_cv" type="file" accept=".docx,.xlsx,.pdf,.jpeg,.jpg"/><br/>職務経歴書（学生の方は職務経歴に準ずるもの）アップロード（1MBまで)</FileInputLable>
                    </InquiryFormWrapper1>
                    <InquiryFormWrapper2>
                        <CurrentSituation>
                            <p style={{margin:0}}>現在の就業状況</p>
                            <ButtonArrange>
                                <label><input type="radio" name="at_inquiry_working_status" value="1" />在職中</label>
                                <label><input type="radio" name="at_inquiry_working_status" value="2"/>無職</label>
                                <label><input type="radio" name="at_inquiry_working_status" value="3"/>学生</label>
                            </ButtonArrange>
                        </CurrentSituation>
                        <MemoArea id="at_inquiry_comment" placeholder="その他連絡事項"/>
                        <Prefecture/>
                        
                        <AgreementMatters>
                        株式会社SuiteUP（以下「当社」という）は、採用活動にご応募いただいた皆様（以下「応募者」という）の住所・氏名・年齢・電話番号・メールアドレス、応募者の経歴・職歴等または筆記試験・適性検査・リファレンスチェック、面接等の採用活動を通じて入手した応募者の情報(応募者の同意を得た上で取得する面接の録画・録音データを含む。以下「個人情報」という）について、以下の通り適切かつ厳格に取り扱います。
                        <br/><br/>
                        1. 当社は、応募者よりご提出いただいた個人情報を採用選考業務（採用に関連する人事企画、施策策定、施策の実施に向けた分析、検討、施策の実施等を含む）にのみ利用しその他の目的には一切使用することはありません。
                        <br/>
                        2. 当社に個人情報をご提出いただくことは、あくまでも応募者の任意となります。ただし、採用選考に必要な個人情報をご提出いただけない場合、またはご提出いただいた個人情報に不備があった場合は、その後の採用活動ができないことがあります。
                        <br/>
                        3. 個人情報は、採用関係者以外の者が取り扱うことはありません。管理責任者のもとで、漏えい・紛失・改ざん・不正アクセス・不正使用などのないよう、適切な安全対策を講じます。
                        <br/>
                        4. 応募者よりお預かりした個人情報は、弊社が定める文書管理規定および社内管理規定により厳重に保管し、定められた保有期間の終了後に適切な方法で処分します。
                        <br/>
                        5. 第3項にかかわらず、個人情報は外部の業務委託先に預けることがあります。この場合、十分な個人情報の保護の水準を満たしている委託先を選定し、個人情報の保護に関する委託契約を締結すると共に、委託先に対する管理・監督を徹底します。
                        <br/>
                        6. 当社は、下記いずれかに該当する場合を除き、応募者の同意を得ることなく応募者の個人情報を第三者へ開示することはありません。
                        <br/>　1. 法令等の要請がある場合
                        <br/>　2. 応募者または公衆の生命、健康、財産などの重要な利益を保護するために必要な場合
                        <br/>　3. 前項に基づいて業務委託先に提供する場合
                        <br/>
                        7. 応募者には、個人情報の利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止を求める権利があります。これらについて希望される場合は、お問い合わせフォームからご連絡ください。ご本人であることを確認させていただいた上で、特別な理由がない限り当社の定める方法により適切に対応いたします。
                        <br/><br/>
                            問い合わせ窓口：株式会社SuiteUPのお問合せフォーム
                            <br/>
                            個人情報保護管理責任者：株式会社SuiteUP代表取締役
                        </AgreementMatters>
                        <AgreementCheckbox><input type="checkbox" id="at_inquiry_consent" onChange={(e) => inquiryFillCheck(e)}/>上記、個人情報に関する取り扱いに同意する</AgreementCheckbox>
                        <ButtonWrapper>
                            <InquirySubmitButton submitButtonPoint={submitButtonPoint} submitButtonColor={submitButtonColor} type="submit" value="Submit" onClick={inquiryPost}/>
                        </ButtonWrapper>
                        <br/>
                    </InquiryFormWrapper2>
                    <br/>
                </RecruitArticleWrapper>
            </GroundWrapper>
        </>
    )
});